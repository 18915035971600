@font-face {
    font-family: 'robotoregular';
    src: url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'fira';
    src: url('../fonts/Fira_Sans/FiraSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
$font-titre: fira, sans-serif;
$font-texte: robotoregular;
$rouge: #FF6658;
$saumon: #FFD1CD;
$bleu: #003A78;
$gris: #E9E4E3;
$blanc: #ffffff;
$noir: rgb(29, 29, 29);
$goutiere: 20px;
$goutiere-min:0px;
$largeurblock: 350px;
$break-small: 500px;
$hauteur-menu: 60px;
$maxWidth: 1250px;
body {
  font-family: $font-texte;
  font-size:1em;
  color:$noir;
}
strong {
  font-weight: bold;
}
h1,h2 {
  font-family: $font-titre;
  padding: $goutiere;
}
h3,h4,h5,h6 {
  font-family: $font-titre;
  padding: $goutiere 0;
}
h1 {
  font-size: 3.157em;
  align-self: start;
  grid-column: 1 / -1; // etire sur toute la largeur de la grid
  color: $bleu;

}
h2 {font-size: 2.369em;}
h3 {font-size: 1.777em;}
h4 {font-size: 1.333em;}
.touteLaLigne {
  grid-column: 1 / -1; // etire sur toute la largeur de la grid
}
.deuxBlockLigne {
  grid-column: 1 / -2; // etire sur toute la largeur de la grid moins 1 bloc
}
@media screen and (min-width: $break-small) {
  .deuxBlockLigneFin {
    grid-column: 2 / -1;
  }
}
@media screen and (max-width: $break-small) {
  h1 {
    font-size: 2.0074em;
  }
  h2 {font-size: 1.728em;}
  h3 {font-size: 1.44em;}
  h4 {font-size: 1.2em;}
  .deuxBlockLigneFin {
    grid-column:auto-fit, minmax($largeurblock, 1fr);
  }
}
a {
  color: $bleu;
}
a:hover {
  text-decoration: underline;
}