// liens d'evitement
div.direct a {
	display: inline-block;
	position: absolute;
	left: -467em;
	z-index: 1000;
	height: 0;
}
div.direct a:focus{
	position: relative;
	left: 0.8em;
	@media screen and (min-width: $break-small) {
		left: 10em;
	}
	background-color: $blanc;
	color: $bleu;
	top: 0.6em;
	min-height: 2em;
}

// header principal du menu
header.conteneur {
		display: flex;
		flex-direction: column;
		
// la bar de navigation avec btn + logo
#bar-nav {
  width: 100%;
  height: $hauteur-menu;
  background-color: $bleu;
  position: fixed;
  display: flex;
  top:0;
  left:0;
  z-index:11;

  img#logo {
    display: inline-block; 
    height: auto;
    margin: 0 auto;
  }
  #nav-toggle {
    height: $hauteur-menu;
    width: $hauteur-menu;
    border: none;
    margin-right: - $hauteur-menu;
    color:$blanc;
    background-color: $bleu;
    position: relative ;
    padding: 0.7em 0 0 0.3em;
    span {
      font-family: $font-texte;
    }  
  }
  #nav-toggle:hover img,#nav-toggle:focus img{
    height: 15px;
    width: 22px;
  }
  #visage {
    height: $hauteur-menu;
    width:45px;
    border: none;
    margin-left: - $hauteur-menu;
    color:$blanc;
    position: relative ;
    padding: 0.9em 0.3em 0 0;
    span {
      font-family: $font-texte;
    }
    }
  #visage:hover img,#visage:focus img {
    height: 35px;
    width: 35px;
  }
  }
}
// le panneau contenant le menu
#panneau_menu_lateral {
  z-index: 12;
  overflow: auto;
  a {color: $blanc;}
  color:$blanc;
	width: 18em;
	background-color:$bleu;
	height: 100%;
	position: fixed;
	top: 0;
  box-shadow: 0 0 0.6em 0 rgba(0,0,0,0.22), 0 0.6em 0.6em 0 rgba(0,0,0,0.30);
    div {
      padding:$goutiere;
      margin-top: $goutiere*2;
      padding-left:$goutiere*2;
    }
    span {
      margin-right: 0.5em;
      bottom:0.2em;
      position : relative;
    }
    ul {
      list-style-type: square;
    }
   
  }
// superposition à l'ouverture du menu
#hoverlay {
    width: 100%;
    height: 10000px;
    background-color: #fff;
    opacity: 0.8;
    position: absolute;
    top:0;
    left:0;
    z-index:10;
}
// le bouton d'ouverture du menu

#nav-toggle:hover img,#nav-toggle:focus img{
  height: 15px;
  width: 22px;
}
// le bouton de fermeture du menu
#fermer_menu {
  z-index: 14;
  position: relative;
  color: #FFFFFF;
  align-items: center;
  padding: 0.3em 0.3em;
  background-color: transparent;
  border:none;
  float: right;
  span {
    margin-right: 0.5em;
    bottom:0.2em;
    position : relative;
  }
}
#fermer_menu:hover, #fermer_menu:focus{
  text-decoration: underline;
}

