@import "partials/reset";
@import "partials/config";
@import "partials/menu-haut";
@import "partials/animate.min";
@import "partials/footer";

.gererAbos{
  text-align: center;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  background-color: #ffd1cd;
    table {

    margin:$goutiere;
    
    thead {
      font-size: 1.1em;
      font-weight: bold;
      tr th {padding: $goutiere;}
      
    }
    tr {
      border: 1px solid $noir;
      td {
        padding: $goutiere;
        }
      }
    }
}
.enteteAbo{
width:100%;
background-color:#ff6658;
}
.siAucunAbo{
padding: 70px;
}
a {
  text-decoration: none;
}
#app {
  margin: 0;
  padding: 0;
}
*:focus {
  border-bottom:1px solid $rouge;
}
#entete {
  margin:0;
  margin-top: $hauteur-menu;
  display: flex;
  background-color: $saumon;
  color:$bleu;
  align-items: center;
  padding:10px 5px;
  width: 100%;
  a {
    color: $bleu;
  }
  a:hover {
    text-decoration: underline;
  }
  
}
#entete.cartouche_pres {
  background-color: $blanc;
  padding: $goutiere 0;
}
.loading{
  margin: 0 $goutiere/4;
  margin-right: $goutiere/2;
  width: $goutiere*2;
  height: $goutiere*2;
  display: inline-block;
  background: url(../images/picto_plot.svg) no-repeat center;
  animation: tourner 1200ms infinite ease-in-out;
  transform: rotate(0);
}
.plot {
  margin: 0 $goutiere/4;
  margin-right: $goutiere/2;
  min-width: $goutiere*2;
  min-height: $goutiere*2;
  display: inline-block;
  background: url(../images/picto_plot.svg) no-repeat center;
}
hr {
	border: none;
	background-image: url("../images/hachures.svg");
  background-repeat: repeat-x;
	height: 8px;
  margin: 0 $goutiere $goutiere $goutiere;
}
.partage {
  margin: 0 $goutiere $goutiere $goutiere;
  display: flex;
  justify-content: space-between;
  align-content: center;
  .rs {
    display: flex;
    justify-content: flex-end;
  }
  a {
    padding: 0 10px;
  }
}
#map {
  height: 100%;
  min-height: 700px;
  z-index:1;
  @media screen and (max-width: $break-small) {
    min-height: 300px;
  }
  h4 {
    padding:0;
  }
}
#contenu {
  max-width: $maxWidth;
  margin:0 auto;
}
#page{
  max-width: $maxWidth;
  margin:0 auto;
  display: grid;
  grid-gap: $goutiere;
  @media screen and (max-width: $break-small) {
    grid-gap: $goutiere-min;
    margin: 0 $goutiere-min;
  }
  grid-template-columns: repeat(auto-fit, minmax($largeurblock, 1fr));
  min-height: 100vh;
  &.article {
    p {
      margin: $goutiere 0;
    }
  }
}
.bloc_info_carte {
  ul {
    li {
    display: flex;
    align-items: center;
    }
  }
}

.fiches {   
  background-color: $rouge;
  position: relative;
  //text-transform: capitalize
  h3 a{
    color:$noir;
    text-decoration: none;
  }
  h2.blanc {
    color: $blanc;
    padding: $goutiere/2 $goutiere/2 $goutiere/2 $goutiere*4;
    border-bottom: 19px solid transparent;
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-image: url('../images/hachure_orange.png') 0 0 19 0 round;
  }
  p, div {
    background-color: $saumon;
    padding: $goutiere/2 $goutiere;
  }
  .picto-titre {
    z-index: 2;
    margin: 0 $goutiere;
    min-width: $goutiere*3;
    min-height: $goutiere*3;
    position: absolute;
    top:-($goutiere*1.5 - 2px);
    display: inline-block;
    background: url(../images/picto_grue.svg) no-repeat center;
  }
  ul {
    background-color: $saumon;
    padding: 0 $goutiere;
    li {
      padding: $goutiere/2 0;
      border-bottom: 1px solid $blanc;
      line-height: 1.3em;
      strong {
        text-transform: uppercase;
      }
    }
    li.illustration img {
        width: 100%; 
      }
    li:last-child {
      border-bottom: none;
    }
  }
}

.fiches.accueil {
  margin-bottom: $goutiere;
  background-color: $saumon;
  display: grid;
  align-content: space-between;
  h3,h4 {
    background-color: $rouge;
  }
  h4 a {
    color: $noir;
   
  }
}
.complement, .action {
  img {
    width:100%;
    height: auto;
    padding: 0 $goutiere;
  }
  overflow: hidden; 
  background-color: $gris;
  p {
    padding: 0 $goutiere;
  }
  a.lire_suite {
    display: block;
    float:right;
    margin: $goutiere;
  }
  h3 {
    padding: $goutiere;
  }
}
.action {
  background-color: #fff;
}
.formulaire{
  padding-bottom: $goutiere;
  background-color: $rouge;
  form {
    padding: 0 $goutiere;
  }
  a {
    color: $noir;
    margin-left: $goutiere;
    display: inline-block;
  }
  h4 {
    margin-left: $goutiere;
  }
  table {
    margin:$goutiere;
    thead {
      font-size: 1.1em;
      font-weight: bold;
      tr th {padding: $goutiere;}
      
    }
    tr {
      border: 1px solid $noir;
      td {
        padding: $goutiere;
        }
      }
    }
}
select {
  padding: $goutiere/2 $goutiere;
  border:0;
  background-color: $blanc;
  font-size: 1em;
}
label {
  display: inline-block;
  margin-bottom: $goutiere/2;
}
input {
  margin-bottom: $goutiere;
  padding: $goutiere/2;
  width: 100%;
}
input[type="submit"], .btn{
  color:$noir;
  cursor:pointer;
  width: auto;
  background-color: $blanc;
  border-radius: $goutiere;
  padding: $goutiere/2 $goutiere;
  border:1px solid $blanc;
  &:hover { 
    border:1px solid $noir;
  }
  &:focus { 
    border:1px solid $noir;
  }
  &.popup {
    display: inline-block;
    background-color: $saumon;
    margin:$goutiere/2 auto 0;
    color:$noir;
  }
 }
#titreConnection{
  margin-bottom:$goutiere;
}

.chargement {
  color:#fff;
  p {
    background-color: $blanc;
  }
}
@keyframes tourner{
  100%{
    transform: rotate(360deg);
}
}
// Pour le modal :
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

 // Fin du Modal
