#footer {
    margin-top: $goutiere;
    background-color: $bleu;
    padding:$goutiere/2 $goutiere;
    ul li {
        display: inline;
    }
    ul li a{
        display: inline-block;
        margin-left: $goutiere;
        color: #fff;
        &:hover {
            color: $saumon;
        }
        
    }
}